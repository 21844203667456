/* You can add global styles to this file, and also import other style files */
@import "./assets/styles/mixin/input-mixin.scss";
@import "./assets/styles/mixin/radio-button-mixin.scss";
@import "./assets/styles/mixin/table-mixin.scss";
@import "./assets/styles/mixin/paginator-mixin.scss";
@import "./assets/styles/mixin/mat-select-mixin.scss";
@import "./assets/styles/mixin/toggle-button-mixin.scss";
@import "./assets/styles/mixin/progress-spinner-mixin.scss";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

:root {
  --clr-prime: #bf293e;
  --clr-white: #ffffff;
  --clr-pink: #f8e7ea;
  --clr-secondary: #000000;
  --clr-prime-lite: rgba(238, 58, 83, 0.6);
  --clr-prime-dark: rgba(191, 41, 62, 0.08);
  --clr-green: #35e08f;
  --clr-green-lite: rgba(53, 224, 143, 0.1);
  --clr-yellow: #ffa011;
  --clr-yellow-lite: gba(255, 160, 17, 0.1);

  --mdc-outlined-text-field-caret-color: var(--clr-prime);
  --mdc-outlined-text-field-focus-outline-color: var(--clr-prime);
  --mdc-outlined-text-field-focus-label-text-color: var(--clr-prime-lite);
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

mat-icon {
  font-family: 'Material Icons' !important;
}

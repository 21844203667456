@mixin select-theme() {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mat-mdc-form-field-infix {
    padding-top: 6px !important;
    padding-bottom: 0 !important;
  }

  .mat-mdc-text-field-wrapper {
    height: 36px !important;
    margin-top: 0 !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__notch {
    border: none;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__trailing {
    border-radius: 18px !important;
    border: 1px solid var(--clr-prime) !important;
    background: var(--clr-prime-dark);
  }

  .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
  .mat-primary
    .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
    color: var(--clr-prime);
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
    .mdc-list-item__primary-text {
    color: var(--clr-prime);
  }

  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    width: 0 !important;
  }

  .mat-mdc-form-field-infix {
    width: 100%;
    height: 100%;
    min-height: auto;
  }
  .mat-mdc-select-arrow svg {
    fill: var(--clr-prime) !important;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::before {
    border-bottom-color: transparent !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    z-index: -1;
  }

  .mdc-text-field--filled .mdc-line-ripple::before {
    border-bottom-width: 0 !important;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::after {
    border-bottom-color: transparent !important;
  }
  .mdc-text-field--filled .mdc-line-ripple::after {
    border-bottom-width: 0 !important;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: var(--clr-prime-dark) !important;
    border-radius: 18px;
    border: 1px solid var(--clr-prime);
    overflow: visible;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
      .mdc-text-field--textarea
    )
    .mat-mdc-form-field-infix {
    padding-top: 4px;
  }

  .mat-datepicker-toggle {
    color: var(--clr-prime) !important;
  }

  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    height: auto;
  }

  button:focus {
    outline: none !important;
  }

  .mat-mdc-icon-button {
    --mdc-icon-button-icon-color: inherit;
    --mat-mdc-button-persistent-ripple-color: transparent;
    --mat-mdc-button-ripple-color: transparent;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }

  .mat-mdc-form-field-flex {
    height: 36px;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-text-field__input {
    caret-color: var(--clr-prime) !important;
    color: var(--clr-prime) !important;
  }

  .mat-date-range-input-separator {
    color: var(--clr-prime) !important;
  }

  ::-webkit-input-placeholder {
    color: var(--clr-prime) !important;
  }

  .mat-calendar-body-selected {
    background-color: var(--clr-prime) !important;
    color: var(--clr-white) !important;
  }

  .mat-calendar-body-cell-content {
    // border-color: var(--clr-prime) !important ;
  }
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
    .mdc-list-item__primary-text {
    color: var(--clr-prime) !important;
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    border-color: transparent !important;
    background-color: var(--clr-prime) !important;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    background-color: var(--clr-prime-dark) !important;
  }

  .mat-calendar-body-in-range::before {
    background: var(--clr-prime-dark) !important;
  }

  .mat-mdc-option .mdc-list-item__primary-text {
    font-size: 10px !important;
  }

  .mat-mdc-select {
    color: var(--clr-prime) !important;
    font-size: 14px !important;
  }

  .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    background-color: var(--clr-prime-dark) !important;
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
      .mat-mdc-option-multiple
    ) {
    background-color: var(--clr-prime) !important;
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
    .mdc-list-item__primary-text {
    color: var(--clr-white) !important;
  }

  .mat-mdc-option:focus.mdc-list-item,
  .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
    background-color: transparent !important;
  }

  .mat-toolbar-row,
  .mat-toolbar-single-row {
    height: auto !important;
  }
  .mat-mdc-button .mat-mdc-button-persistent-ripple::before,
  .mat-mdc-unelevated-button .mat-mdc-button-persistent-ripple::before,
  .mat-mdc-raised-button .mat-mdc-button-persistent-ripple::before,
  .mat-mdc-outlined-button .mat-mdc-button-persistent-ripple::before {
    background-color: var(--clr-prime-dark) !important;
  }

  mat-form-field {
    width: 100%;
  }
}

@include select-theme();

@mixin radio-button-theme() {
  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-disabled-selected-icon-color: #000;
    --mdc-radio-disabled-unselected-icon-color: #000;
    --mdc-radio-unselected-hover-icon-color: #212121;
    --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-selected-focus-icon-color: var(--clr-prime);
    --mdc-radio-selected-hover-icon-color: var(--clr-prime);
    --mdc-radio-selected-icon-color: var(--clr-prime);
    --mdc-radio-selected-pressed-icon-color: var(--clr-prime);
    --mat-radio-ripple-color: #000;
    --mat-radio-checked-ripple-color: var(--clr-prime);
    --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  }
  mat-radio-button label {
    margin-bottom: 0 !important;
  }
}

@include radio-button-theme();

@mixin paginator-mixin() {
  .mat-mdc-paginator-range-actions {
    .mat-mdc-icon-button .mat-mdc-button-touch-target {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid var(--clr-prime);
      background: rgba(191, 41, 62, 0.1);
      color: var(--clr-secondary);
      font-size: 16px;
      font-weight: 600;
    }

    .mat-mdc-icon-button {
        margin-right: 10px; 
    }

    .mat-mdc-paginator-icon {
        fill: var(--clr-prime);
    }
  }
}

@include paginator-mixin();

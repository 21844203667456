@mixin table-theme() {
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px !important;
  }
  .mat-mdc-table {
    background-color: transparent !important;
  }
  table tr:last-child td /*To remove the last border*/ {
    border-bottom: 0 solid !important;
  }
  .mat-elevation-z8,
  .mat-mdc-elevation-specific.mat-elevation-z8 {
    box-shadow: 0px 2px 10px 0px rgba(191, 41, 62, 0.31);
  }

  .mat-mdc-header-row {
    color: var(--clr-white) !important;
  }
  .mat-mdc-header-row :first-child {
    border-top-left-radius: 8px !important;
  }
  .mat-mdc-header-row :last-child {
    border-top-right-radius: 8px !important;
  }

  .mat-mdc-header-row :nth-child(n) {
    background-color: var(--clr-prime) !important;
  }
  .mat-mdc-row:nth-child(odd) {
    background-color: rgba(191, 41, 62, 0.04);
  }
  .mat-mdc-row:hover .mat-mdc-cell {
    background-color: var(--clr-pink);
  }

  .mat-mdc-row:hover {
    transition: 0.5s !important;
    transform: scale(1.025) !important;
    border-radius: 4px;
    box-shadow: 0px 2px 4px 0px rgba(86, 7, 18, 0.17);
    cursor: pointer;
  }

  .mat-mdc-header-cell,
  .mat-mdc-cell {
    position: relative;

    &:last-child::after {
      content: none;
    }
  }
  .mat-mdc-header-cell::after,
  .mat-mdc-cell::after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 28px;
    background: var(--clr-prime);
    opacity: 0.2;
    position: absolute;
    right: 21px;
    top: 10px;
  }

  .mat-mdc-header-cell::after {
    background-color: var(--clr-white);
    opacity: 0.8;
    top: 20px;
    height: 17px;
  }
}

@include table-theme();


@mixin mat-button-toggle-checked-mixin() {
  .mat-button-toggle-group {
    .mat-button-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid var(--clr-prime);
      background: var(--clr-white);
      color: var(--clr-secondary);
      font-size: 16px;
      font-weight: 600;
    }

    .mat-button-toggle-checked {
      background: var(--clr-prime) !important;
      color: var(--clr-white) !important;
    }
  }
  .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
  .mat-button-toggle-group-appearance-standard {
    border: none !important;
  }
}

@include mat-button-toggle-checked-mixin();
